<template>
  <div class="archause_home">
    <div class="mt0 mobile-mt0 extended wfill centered flex">
      <div
        class="arcause_hero"
        v-if="pageInfo.banner && pageInfo.mobileBanner"
        :style="{
          background:
            $mq != 'mobile'
              ? 'url(' +
                pageInfo.banner.url +
                ') top / contain no-repeat, url(../assets/arcause_final.jpg) top / contain no-repeat'
              : 'url(' +
                pageInfo.mobileBanner.url +
                ') top / contain no-repeat, url(../assets/arcause_mobile.jpg) top / contain no-repeat'
        }"
      ></div>
    </div>
    <div class="h20 white wfill m20"></div>
    <div class="wrap extended flex section2" style="z-index: -1;">
      <div
        v-if="$mq != 'mobile'"
        style="padding: 40px 0px 40px 140px; height: 400px;"
        class="section left flex vertical"
      >
        <h6>What is Industreal?</h6>
        <p class="mt20 fs14" v-html="pageInfo.description"></p>
      </div>
      <!-- <div v-if="$mq != 'mobile'" class="hfill smear">
      </div>
      <div v-if="$mq != 'mobile'" class="cover"></div> -->
      <div
        v-if="$mq == 'mobile'"
        style="padding: 40px 80px;"
        class="section left centered flex vertical"
      >
        <h1 class="fs32">What is Industreal?</h1>
        <p class="mt20 fs14" v-html="pageInfo.description"></p>
      </div>
    </div>
    <div>
      <div v-for="s in pageInfo.sections" :key="s.id">
        <div v-if="s.type == 'html'" v-html="s.html"></div>
        <campaign
          v-if="active && active.id && s.type == 'registration'"
          :campaign="active"
          isPage="true"
        />
        <div v-if="s.type == 'text'" class="px40" v-html="s.text"></div>
        <div v-if="s.type == 'textNimage'" class="textNimage p40">
          <img style="display: block" height="100%" :src="s.images[0].url" />
          <div v-html="s.text" class="mauto px20 text"></div>
        </div>
        <div v-if="s.type == 'files'">
          <div v-if="s.files && s.files.length > 0">
            <h6 class="fs16 mt32 mb12">Attachments</h6>
            <a
              class="files pointer mt4"
              v-for="f in s.files"
              @click="$bus.redirect(f.url)"
              :key="f.name"
              >{{ f.name }}</a
            >
          </div>
        </div>
        <!-- img v-if="$mq == 'desktop' && s.images && s.images[0] && s.type == 'image'" 
				class="mxauto"
				style="display: block; height: 70vh; text-align: center;" 
				:src="s.images[0].url" -->
        <img
          v-if="s.images && s.images[0] && s.type == 'image'"
          style="width: 100%;"
          :src="s.images[0].url"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
// import campaign from '../components/campaign'
export default {
  name: "Industreal",
  metaInfo() {
    return {
      title: this.pageInfo?.SEO?.metaTitle || "Ethos | Industreal",
      meta: [
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
        {
          hid: "description",
          name: "description",
          content:
            this.pageInfo?.SEO?.metaDescription ||
            "Content and curation services connecting Industries in the building sector with the Architecture, Construction, Engineering & Design (ACED) Professionals."
        },
        {
          hid: "keywords",
          name: "keywords",
          content: this.pageInfo?.SEO?.metaKeywords || ""
        }
      ]
    };
  },
  data() {
    return {
      campaigns: [],
      pageInfo: {},
      search: "",
      filter: {
        activity: ""
      }
    };
  },
  computed: {
    pastCampaigns: function() {
      let campaigns = [...this.campaigns];
      return campaigns
        .filter(c => {
          return this.parseDate(c.End) <= this.parseDate(moment()._d);
        })
        .sort((c1, c2) => {
          if (c1.priority != c2.priority) {
            let e1priority = c1.priority || 10;
            let e2priority = c2.priority || 10;
            return e1priority - e2priority;
          }
          return this.parseDate(c2.Start) - this.parseDate(c1.Start);
        });
    },
    presentCampaigns: function() {
      let campaigns = [...this.campaigns];
      return campaigns
        .filter(c => {
          return this.parseDate(c.End) > this.parseDate(moment()._d);
        })
        .sort((c1, c2) => {
          if (c1.priority != c2.priority) {
            let e1priority = c1.priority || 10;
            let e2priority = c2.priority || 10;
            return e1priority - e2priority;
          }
          return this.parseDate(c2.Start) - this.parseDate(c1.Start);
        });
    },
    sortedCampaigns: function() {
      let events = [...this.campaigns];
      events = events.filter(ev => !ev.hide);
      if (this.search) {
        events = events.filter(e => {
          let title = e.title || e.Title;
          let description = e.description || e.Information;
          return (
            title.toLowerCase().includes(this.search.toLowerCase()) ||
            description.toLowerCase().includes(this.search.toLowerCase())
          );
        });
      }
      let f = this.filter;
      switch (f.activity) {
        case "active":
          events = events.filter(e => {
            return (
              moment(e.Start).unix() <= moment().unix() &&
              moment(e.End).unix() >= moment().unix()
            );
          });
          break;
        case "past":
          events = events.filter(e => {
            return moment(e.End).unix() < moment().unix();
          });
          break;
        case "future":
          events = events.filter(e => {
            return moment(e.Start).unix() > moment().unix();
          });
      }
      return events?.sort((c1, c2) => {
        if (c1.priority != c2.priority) {
          let e1priority = c1.priority || 10;
          let e2priority = c2.priority || 10;
          return e1priority - e2priority;
        }
        return this.parseDate(c2.Start) - this.parseDate(c1.Start);
      });
    }
  },
  mounted() {
    this.$req
      .get("industreal")
      .then(r => {
        this.pageInfo = r.data;
      })
      .catch(e => console.log(e));
  },
  methods: {
    scrollToCampaigns: function() {
      var elmnt = document.getElementById("campaigns");
      elmnt.scrollIntoView({ behavior: "smooth" });
    },
    parseDate: function(d) {
      return parseInt(moment(d).format("YYYYMMDD"));
    }
  }
};
</script>

<style lang="css" scoped>
.archause_home {
  width: 100%;
  position: relative;
  padding: 0px;
  overflow: hidden;
}

@media (max-width: 800px) {
  .archause_home {
    padding: 0px;
  }
}
.banner {
  padding: 8px;
  height: fit-content;
  white-space: nowrap;
  background: #252525;
  display: flex;
  justify-content: center;
}

.banner p {
  height: 24px;
  font-size: 16px;
  margin: auto 4px;
  font-weight: 600;
}
@media (max-width: 800px) {
  .banner p {
    font-size: 14px;
  }
  .banner {
    overflow-x: scroll;
  }
}
.banner::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.banner {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.arcause_hero {
  height: 100vw;
  width: 100%;
  /* background: url("../assets/arcause_mobile.jpg") top / contain no-repeat; */
}
@media (min-width: 600px) {
  .arcause_hero {
    height: 37vw;
    width: 100%;
  }
}

/* .smear {
    background: url('../assets/archause_section_smear.svg');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 300px;
    position: relative;
    height: 500px;
    top: -190px;
    z-index: -1;
    left: 190px;
  } */

.section2 {
  height: 400px;
}
@media (max-width: 800px) {
  .section2 {
    height: fit-content;
  }
}
.section.left {
  width: calc(40%);
  position: relative;
  padding: 20px;
}
.section.right {
  background: url("../assets/smear.svg");
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: top;
  padding: 0px;
  width: 60%;
}

.events {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  position: relative;
  justify-content: flex-start;
}

.hands {
  background: url("../assets/hands.svg");
  background-size: contain;
  background-position: bottom;
  background-repeat: no-repeat;
  width: 100%;
  bottom: 0px;
  height: 30%;
}

.event_card {
  border: 1px solid #eee;
  width: calc(220px - 20px);
  margin: 12px 20px 12px 0px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  height: fit-content;
  position: relative;
  border-radius: 3px;
  overflow: hidden;
  height: 340px;
}
.event_card .label {
  color: white;
  right: -8px;
  top: 20px;
  position: absolute;
  padding: 4px 12px;
  border-radius: 2px;
  font-size: 11px;
}
.event_card .desc * {
  font-size: 11px;
}
@media (max-width: 800px) {
  .event_card {
    width: 100%;
    margin: 12px 0px;
  }
}
.event_card .img {
  width: 100%;
  min-height: 200px;
}
.event_card .bottom {
  width: 100%;
  padding: 12px;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
}

/* .cover {
    border-bottom-left-radius: 20px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 300px 80px;
    border-color: transparent transparent #B98744 transparent;
  } */
@media (max-width: 800px) {
  .section.left {
    width: 100%;
    position: relative;
  }
  .section.right {
    background: #b98744;
    width: 100%;
    position: relative;
  }
  .mobile-center {
    text-align: center;
  }
}
@media (min-width: 800px) {
  .together-text {
    font-size: 20px;
    margin-left: auto;
    margin: auto;
  }
}
</style>
