var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"archause_home"},[_c('div',{staticClass:"mt0 mobile-mt0 extended wfill centered flex"},[(_vm.pageInfo.banner && _vm.pageInfo.mobileBanner)?_c('div',{staticClass:"arcause_hero",style:({
          background:
            _vm.$mq != 'mobile'
              ? 'url(' +
                _vm.pageInfo.banner.url +
                ') top / contain no-repeat, url(../assets/arcause_final.jpg) top / contain no-repeat'
              : 'url(' +
                _vm.pageInfo.mobileBanner.url +
                ') top / contain no-repeat, url(../assets/arcause_mobile.jpg) top / contain no-repeat'
        })}):_vm._e()]),_c('div',{staticClass:"h20 white wfill m20"}),_c('div',{staticClass:"wrap extended flex section2",staticStyle:{"z-index":"-1"}},[(_vm.$mq != 'mobile')?_c('div',{staticClass:"section left flex vertical",staticStyle:{"padding":"40px 0px 40px 140px","height":"400px"}},[_c('h6',[_vm._v("What is Industreal?")]),_c('p',{staticClass:"mt20 fs14",domProps:{"innerHTML":_vm._s(_vm.pageInfo.description)}})]):_vm._e(),(_vm.$mq == 'mobile')?_c('div',{staticClass:"section left centered flex vertical",staticStyle:{"padding":"40px 80px"}},[_c('h1',{staticClass:"fs32"},[_vm._v("What is Industreal?")]),_c('p',{staticClass:"mt20 fs14",domProps:{"innerHTML":_vm._s(_vm.pageInfo.description)}})]):_vm._e()]),_c('div',_vm._l((_vm.pageInfo.sections),function(s){return _c('div',{key:s.id},[(s.type == 'html')?_c('div',{domProps:{"innerHTML":_vm._s(s.html)}}):_vm._e(),(_vm.active && _vm.active.id && s.type == 'registration')?_c('campaign',{attrs:{"campaign":_vm.active,"isPage":"true"}}):_vm._e(),(s.type == 'text')?_c('div',{staticClass:"px40",domProps:{"innerHTML":_vm._s(s.text)}}):_vm._e(),(s.type == 'textNimage')?_c('div',{staticClass:"textNimage p40"},[_c('img',{staticStyle:{"display":"block"},attrs:{"height":"100%","src":s.images[0].url}}),_c('div',{staticClass:"mauto px20 text",domProps:{"innerHTML":_vm._s(s.text)}})]):_vm._e(),(s.type == 'files')?_c('div',[(s.files && s.files.length > 0)?_c('div',[_c('h6',{staticClass:"fs16 mt32 mb12"},[_vm._v("Attachments")]),_vm._l((s.files),function(f){return _c('a',{key:f.name,staticClass:"files pointer mt4",on:{"click":function($event){return _vm.$bus.redirect(f.url)}}},[_vm._v(_vm._s(f.name))])})],2):_vm._e()]):_vm._e(),(s.images && s.images[0] && s.type == 'image')?_c('img',{staticStyle:{"width":"100%"},attrs:{"src":s.images[0].url}}):_vm._e()],1)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }